import img12 from '../Images/img12.svg';
import img13 from '../Images/img13.svg';
import img14 from '../Images/img14.svg';
import img15 from '../Images/img15.svg';
import React from 'react';
import '../App.css';
import { Link } from 'react-router-dom';

const About = () => {
    return (
        <div className='pb-5'>
        <div className="d-flex flex-column justify-content-center align-items-center DIV5">
            <div style={{ paddingTop: "200px" }}>
                <h1 className="heading"style={{
                     color: "white"
                }}>ABOUT US</h1>
                <p className='d-flex justify-content-end mb-5 pb-5 TEXT1' style={{
                    color: "#C5C5C5"}}><Link className="nav-link page" style={{color:"#009FFF"}}to="/">Home&gt;</Link> About Page</p>
        </div>

        </div >
        <div className=' d-flex flex-wrap align-items-center justify-content-around mb-5 ' >
                <div style={{ paddingTop: "70px", width: "600px" }}>
                    <p className="styling4"style={{
                         color: "#ffffff"
                    }}>Who We Are</p>
                    <p className="style4" style={{textAlign:"justify",
                        color: "#ffffff"
                    }}>Welcome to Volunteer Management, a leading SaaS management platform that helps businesses optimize their cloud spending and streamline their SaaS operations. Our mission is to empower organizations of all sizes to gain full control and visibility over their SaaS usage, reduce wasteful spending, and improve collaboration and productivity across teams.</p>
                    
                </div>
                <img  className="IMG"style={{ marginTop: "100px" }} src={img12} />
            </div>
            
            <div className='d-flex flex-column justify-content-center align-items-center mt-5'>
            <span className="styling4" style={{color:"#FFFFFF"}}>How Platform Works</span>
            <p style={{fontWeight: '500',
fontSize: '20px',
lineHeight: '38px',
textAlign: 'center',
width:"700px",
color: '#FFFFFF'}}>Manage volunteers and expand your orgainsation</p>
<div className='d-flex flex-wrap gap-5 mt-5' style={{padding:"0px 100px",paddingBottom:"200px"}}>
<div className='d-flex flex-column justify-content-center align-items-center DIV6 py-5 px-5'>
    <img src={img13}/>
<h2 style={{color:"#003555",fontWeight:"700"}}>Start a group</h2>
<p style={{textAlign:"center",color:"#003555",fontWeight: '500',
fontSize: '20px'}}>Our platform integrates with your existing SaaS tools and provides you with real-time insights and analytics on your usage, costs, and compliance status</p>
</div>
<div className='d-flex flex-column justify-content-center align-items-center DIV6 py-5 px-5'>
    <img src={img14}/>
<h2 style={{color:"#003555",fontWeight:"700",textAlign:"center"}}>Social Media Promotion</h2>
<p style={{textAlign:"center",color:"#003555",fontWeight: '500',
fontSize: '20px'}}>It is an important part of communication in any organization. Whether you're sharing a new product launch, a company update, or an upcoming event.You can even viral any content on Twitter, Instagram through in app volunteer collaboration</p>
</div>
<div className='d-flex flex-column justify-content-center align-items-center DIV6 py-5 px-5'>
    <img src={img15}/>
<h2 style={{color:"#003555",fontWeight:"700"}}>Track task</h2>
<p style={{textAlign:"center",color:"#003555",fontWeight: '500',
fontSize: '20px'}}>Tracking tasks is an essential aspect of managing any project, as it helps ensure that everyone involved is aware of what needs to be done and when.</p>
</div>
</div>
</div>
        </div>
    );
}

export default About;
