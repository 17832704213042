import React from 'react';
import { Link } from 'react-router-dom';

const Support = () => {
    return (
        <div>
             <div className="d-flex flex-column justify-content-center align-items-center DIV5">
            <div style={{ paddingTop: "200px" }}>
                <h1 className="heading"style={{
                   color: "white",width:"30%"
                }}>Support</h1>
                <p className='d-flex justify-content-end mb-5 pb-5 TEXT2'  style={{
                    color: "#C5C5C5"}}><Link className="nav-link page" style={{color:"#009FFF"}}to="/">Home&gt;</Link> Support Page</p>
        </div>

        </div >
        <div className="Support"  >
            <h2 style={{color: '#FFFFFF',fontWeight: '700',fontSize:"60px"}}>Need Help? Open a Ticket</h2>
            <p style={{color:"#BCBCBC",fontWeight: '700',
fontSize: '27px'}}>Our Support Team Will Get Back To You ASAP Via Mail.</p>
<form action="https://formsubmit.co/aakashcreation87@gmail.com" method="POST">
       <div class="mb-3 mt-5">
  <label for="exampleFormControlInput1" class="form-label">Your Name</label>
  <input type="text" name="name" class="form-control" id="exampleFormControlInput1" placeholder="Enter Full Name"/>
</div>
<div class="mb-3">
  <label for="exampleFormControlInput1" class="form-label">Email address</label>
  <input type="email" name="email" class="form-control" id="exampleFormControlInput1" placeholder="Enter Your Email"/>
</div>
<input type="hidden" name="_captcha" value="false"/>

<div class="mb-3">
  <label for="exampleFormControlTextarea1" class="form-label">Example textarea</label>
  <textarea name="message" class="form-control" id="exampleFormControlTextarea1" rows="4" placeholder="Enter Your Message" style={{maxWidth:"700px"}}></textarea>
</div>
<button type="submit" class="btn fw-bold px-5 mt-4" style={{background: '#003555',
borderRadius: '10px',color:'#FFFFFF',fontSize:"25px"}}>Send</button>
</form>
        </div>
        </div>
    );
}

export default Support;
