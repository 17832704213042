import {Routes, Route} from 'react-router-dom'
import './App.css';
import { Navbar } from './components/Navbar';
import { Home } from './components/Home';
import About from './components/About';
import { Footer } from './components/Footer';
import Support from './components/Support';

function App() {
    return (
        <div>
          <Navbar/>  
          <Routes>
          <Route path='/' element={<Home/>}/>
          <Route path='/about' element={<About/>}/>
          <Route path='/support' element={<Support/>}/>
          </Routes>
            <Footer/>


        </div>
    );
}

export default App;
