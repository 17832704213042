import React from 'react'


import { Link } from 'react-router-dom';


export const Navbar = () => {
    return (
        <nav class="navbar fixed-top navbar-expand-lg bg-body-tertiary" style={{ background: '#FFFFFF' }}>
            <div class=" container-fluid" >
                <div > <span style={{ fontSize: "30px", fontWeight: "400", color: "#5E5E5E", position: "relative", top: "8px", left: "5px", color: "#00395C" }}>Volunteer</span></div>
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul class="navbar-nav ms-auto mb-2 mb-lg-0">

                        <li class="nav-item">
                            <Link class="nav-link page" to="/about">About Us</Link>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link page" to="#">Blog</a>
                        </li>
                        <li class="nav-item">
                            <Link class="nav-link page" to="/support">Support</Link>
                        </li>
                        <li class="nav-item">
                            <Link class="nav-link page" to="/support">Terms & Conditions</Link>
                        </li>


                    </ul>

                    <ul class="d-flex">

            </ul>
                </div>
            </div>
        </nav>
    )
}
