import React from 'react'

import facebook from '../Images/facebook.svg'
import instagram from '../Images/instagram.svg'
import linkedin from '../Images/linkedin.svg'
import twitter from '../Images/twitter.svg'


export const Footer = () => {
  return (
    
        <div className='footer d-flex flex-column justify-content-center align-items-center DIV4 mt-5'>
            <span className="styling2" style={{}}>
                Volunteer
            </span>
                <p className="style2" style={{}}>Organize your tasks, streamline your workflow, and boost your productivity with our task management app. Simplify your work, achieve your goals, and stay on top of your to-do list with ease.</p>
       <div className='d-flex justify-content-center gap-5' style={{width:"100%"}}>
        <div className=''style={{background: '#003555',
borderRadius: '0px 100px 0px 0px',width: '40vw',marginRight:'23px',
height: '65px'}}>
   
        </div>
        <div className='d-flex gap-5'>
          <img src={facebook}/>
          <img src={twitter}/>
          <img src={linkedin}/>
          <img src={instagram}/>
        </div>
        <div className='' style={{background: '#003555',
borderRadius: '100px 0px 0px 0px',width: '40vw',marginLeft:'20px',
height: '65px'}}>
 
        </div>
       </div>
            </div>

  )
}
