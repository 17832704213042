import React from 'react'
// import img1 from '../Images/img1.svg';
// import img2 from '../Images/img2.svg';
// import img3 from '../Images/img3.svg';
import '../App.css'
import image from '../Images/image.svg';
import img4 from '../Images/img4.svg';
import img5 from '../Images/img5.svg';
import img6 from '../Images/img6.svg';
import img7 from '../Images/img7.svg';
import img8 from '../Images/img8.svg';
import img9 from '../Images/img9.svg';
import Accordion from 'react-bootstrap/Accordion';



export const Home = () => {
  return (
    <div>
         <div style={{ marginTop: "60px", background: 'linearGradient(180deg, #00395C 0%, #81CFFF 100%)', paddingTop: "70px",}}>
                <div className='d-flex flex-column align-items-center justify-content-center ' >
                    <p className='styling'>SaaS Tool <br/>Empowering Through Collaborative<br/> Volunteer Management Solution</p>
                    <p className="style"style={{  }}>Streamlining Engagement: Connect, Collaborate, and Contribute with our Volunteer App.</p>
                    <a href="#"> <button className='btn p-2 mt-3' style={{
                        border: "3px solid #FFFFFF",
                        borderRadius: '10px', color: '#ffffff'
                    }}>Download now</button></a>
                 </div>
                <div className=' d-flex justify-content-center mt-5 DIV' >

                    <img className="img-fluid" src={image}/>
                    {/* <img className="img-fluid Image1" src={img1} style={{maxWidth:"22%"}} />
                    <img className="img-fluid Image3" src={img3} style={{maxWidth:"22%"}}/> */}

                </div> 
                {/* <div className="container d-flex justify-content-center mt-5">
    <div className="row">
        <div className="col-sm-4">
            <img src={img2} className="img-responsive Image2"/>
        </div>
        <div className="col-sm-4">
            <img src={img1} className="img-responsive Image1"/>
        </div>
        <div className="col-sm-4">
            <img src={img3} className="img-responsive Image3"/>
        </div>
    </div>
</div> */}
            </div>
            <div className=' d-flex flex-wrap align-items-center justify-content-around DIV2' style={{ background: "#FFFFFF",width:"100%" }} >
                <div className=""style={{ paddingTop: "70px",width:"600px" }}>
                    <p className="styling4"style={{
                         color: "#00458D",text:"center"
                    }}>Keep in touch with your groups</p>
                    <p className="style4"style={{
                         color: "#4A4A4A"
                    }}>Whether it's planning any event or campaign in the city or state group conversations should feel effortless. Whether it's planning any event or campaign in the city or state group conversations should feel effortless.</p>
                    <a href="#"> <button className='btn px-4 mt-3 BTN' style={{
                        border: "3px solid #0066A4",
                        borderRadius: '10px', fontSize: "15px", fontWeight: "500"
                    }}>Read More</button></a>
                </div>
                <img className="img-fluid IMG" style={{ marginTop: "100px" }} src={img4} />
            </div>
            <div className=' d-flex flex-wrap align-items-center justify-content-around' style={{marginTop:"-150px"}}  >
                <img className="img-fluid IMG mt-5" src={img5} />
                <div className="TEXT1 px-2"style={{ width: "700px"}}>
                    <p className="styling4"style={{
                         color: "#ffffff"
                    }}>Assigning a task is now easy with Our Task Management App</p>
                    <p className="style4"style={{
                        color: "#ffffff",textAlign:"justify"
                    }}>A task management app is a tool designed to help individuals and teams organize, prioritize, and complete tasks efficiently. With this app, you can create a to-do list, assign tasks to team members, set deadlines, track progress, and receive notifications when tasks are due or completed. It provides a centralized platform for team communication, collaboration, and task tracking, eliminating the need for multiple communication channels and reducing the risk of miscommunication.</p>
                   
                </div>

            </div>
            <div className=' d-flex flex-wrap align-items-center justify-content-around ' >
                <div className="px-2"style={{ paddingTop: "70px", width: "600px" }}>
                    <p className="styling4" style={{
                         color: "#ffffff"
          
                    }}>Keep in touch with your Network</p>
                    <p className="style4"style={{
                        color: "#ffffff"
                    }}>Whether it's planning any event or campaign in the city or state group conversations should feel effortless. Whether it's planning any event or campaign in the city or state group conversations should feel effortless.</p>
                    <a href="#"> <button className='btn px-4 mt-3 BTN' style={{
                        border: "3px solid #ffffff",
                        borderRadius: '10px',color:"#ffffff", fontSize: "15px", fontWeight: "500"
                    }}>Read More</button></a>
                </div>
                <img className="img-fluid IMG"  style={{ marginTop: "100px" }} src={img6} />
            </div>
            <div className=' d-flex flex-wrap align-items-center justify-content-around' >
                <img style={{ marginTop: "100px" }} src={img7} className="img-fluid IMG"  />
                <div className="TEXT1 px-2"style={{ paddingTop: "70px", width:"800px",textAlign:"end"}}>
                    <p className="styling4"style={{
                        color: "#ffffff"
                    }}>Install App and Start Using</p>
                    <p className="style4" style={{
                         color: "#ffffff"
                    }}>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.</p>
                   <div className='d-flex justify-content-end gap-4' >
                   <a href="#" style={{ textDecoration:"none"}}> <button className='btn px-4 mt-3 d-flex gap-1 align-items-center' style={{
                        border: "3px solid #ffffff",
                        height:"48px",
                       
                        borderRadius: '10px',color:"#ffffff", fontSize: "15px", fontWeight: "500",background:"#FFFFFF",color:"#0066A4"                   
                         }}><img src={img9}/><p className='mt-3'>App Store</p> </button></a>
                    <a href="#"> <button className='btn px-4 mt-3' style={{
                        border: "3px solid #ffffff",
                       
                        borderRadius: '10px',color:"#ffffff", fontSize: "15px", fontWeight: "500",background:"#FFFFFF",color:"#0066A4"
                    }}><img src={img8}/> Google Play</button></a>
                   </div>
                </div>

            </div>
            <div className='d-flex flex-column justify-content-center align-items-center' style={{marginTop:"100px"}}>
            <span className="styling4"style={{
color:"#FFFFFF",textAlign:"center"}}>Frequently Asked Questions</span>
            <p style={{fontWeight: '500',
fontSize: '15px',
lineHeight: '23px',
textAlign: 'center',

color: '#FFFFFF'}}>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.</p>

<Accordion className="Accord my-5" defaultActiveKey={['0']} alwaysOpen style={{width:"70vw",borderStyle:"none"}}>
      <Accordion.Item eventKey="0" style={{borderStyle:"none"}}>
        <Accordion.Header><p style={{color:"#0066A4",fontWeight:"500"}}>01 &nbsp; How Do I Contact Customer Service</p></Accordion.Header>
        <Accordion.Body style={{background:"#0066A4",color:"#FFFFFF"}}>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat. Duis aute irure dolor in
          reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
          culpa qui officia deserunt mollit anim id est laborum.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1" className='my-2' style={{borderStyle:"none"}}>
        <Accordion.Header><p style={{color:"#0066A4",fontWeight:"500"}}>02 &nbsp; How Do I Contact Customer Service</p></Accordion.Header>
        <Accordion.Body style={{background:" #0066A4",color:"#FFFFFF"}}>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat. Duis aute irure dolor in
          reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
          culpa qui officia deserunt mollit anim id est laborum.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2" className='my-2' style={{borderStyle:"none"}}>
        <Accordion.Header ><p style={{color:"#0066A4",fontWeight:"500"}}>03 &nbsp; How Do I Contact Customer Service</p></Accordion.Header>
        <Accordion.Body style={{background:"#0066A4",color:"#FFFFFF"}}>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat. Duis aute irure dolor in
          reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
          culpa qui officia deserunt mollit anim id est laborum.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="3" className='my-2'style={{borderStyle:"none"}}>
        <Accordion.Header><p style={{color:"#0066A4",fontWeight:"500"}}>04 &nbsp; How Do I Contact Customer Service</p></Accordion.Header>
        <Accordion.Body style={{background:"#0066A4",color:"#FFFFFF"}}>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat. Duis aute irure dolor in
          reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
          culpa qui officia deserunt mollit anim id est laborum.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="4" className='my-2' style={{borderStyle:"none"}}>
        <Accordion.Header><p style={{color:"#0066A4",fontWeight:"500"}}>05 &nbsp; How Do I Contact Customer Service</p></Accordion.Header>
        <Accordion.Body style={{background:"#0066A4",color:"#FFFFFF"}}>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat. Duis aute irure dolor in
          reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
          culpa qui officia deserunt mollit anim id est laborum.
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
            </div>
    </div>
  )
}
